import React from 'react';

import {
  FooterContainer
} from './FooterStyles';

const Footer = () => (
  <FooterContainer>
    <small>&copy; Copyright 2020, Teva Henry</small>
  </FooterContainer>
)

export default Footer;