import styled from 'styled-components'
import { motion } from 'framer-motion'

export const SidePanelContainer = styled(motion.div)`
  grid-area: side;
  background: ${props => props.theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const SkillsContainer = styled(motion.div)`
  height: 90%;
  width: 80%;
  color: ${props => props.theme.colors.accent};
  font-size: 2.3vh;
  line-height: 150%;
`

export const SkillsSide = styled(motion.div)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h3 {
    width: 70%;
    text-transform: uppercase;
  }
`

export const SocialContainer = styled.ul`
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const ContactLink = styled.a`
  padding: 0.8vw;
  background: ${props => props.theme.colors.main};
  border-radius: 0.8vw;
  margin: 1vw;
  transition: transform 0.15s ease-in;

  :hover {
    transform: scale(1.03);
    transition: transform 0.15s ease-in;
  }
`;

export const StyledSVG = styled.svg`
  fill: ${props => props.theme.colors.secondary};
  height: 7vh; 
`