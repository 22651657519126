const projectData = [
  {
    id: 1, 
    area: 'project1', 
    title: 'Seasons', 
    shortDescription: 'E-commerce example.', 
    longDescription: 'This e-com website is built with react/redux in the frontend, and integrates 3rd party services such as Firebase and the Stripe payment system.',
    image: ['ecom-seasons2.png', 'ecom-seasons.png'],
    video: 'ecom1.mp4',
    gitHub: 'https://github.com/TevaHenry/ecom-project',
    demo: 'https://ecom-seasons.herokuapp.com/',
    techStack: ['react', 'redux', 'firebase']
  }, 
  {
    id: 2, 
    area: 'project2', 
    title: 'What, son?',
    shortDescription: 'A language translator app.', 
    longDescription: 'Using IBM\'s Watson APIs & websockets to send audio data in real time, the app records your voice and transcribes it in the original and translated language.', 
    image: ['what-son2.png', 'what-son2.png'],
    video: 'whatson.mp4',
    gitHub: 'https://github.com/TevaHenry/What-son-',
    demo: 'https://what-son.herokuapp.com/',
    techStack: ['react', 'nodejs']
  }, 
  {
    id: 3, 
    area: 'project3', 
    title: 'Blog Scout', 
    shortDescription: 'Fullstack showcase.',
    longDescription: 'As a Fullstack app showcase, you can add your favourite blog posts, which are sorted by most likes, and can be commented on. The Backend is built with Node & MongoDB.', 
    image: ['blog-scout.png', 'blog-scout.png'],
    video: 'blog-scout.mp4',
    gitHub: 'https://github.com/TevaHenry/blog-scout',
    demo: 'https://blog-scout.herokuapp.com/',
    techStack: ['react', 'nodejs', 'mongodb']
  }
]

export default projectData