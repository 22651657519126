import React from 'react'

import { 
  AboutPageContainer, 
  BioContainer, 
  SkillsContainer,
  TextContainer,
  Corner
} from './AboutPageStyles'

const aboutVariants = {
  hidden: {
   y: '100vh'
  },
  hiddenMobile: {
    x: '100vw'
  },
  visible: {
   y: 0,
   transition: {delay: 0.5, duration: 0.7}
  },
  visibleMobile: {
    x: 0,
    transition: {delay: 0.5, duration: 0.7}
   },
  exit: {
    y: '-100vh',
    transition: { ease: 'easeInOut', duration: 0.7}
  },
  exitMobile: {
    x: '100vw',
    transition: { ease: 'easeInOut', duration: 0.7}
  }
}

const AboutPage = ({ isDesktop }) => (
  <AboutPageContainer
    variants={aboutVariants}
    initial={isDesktop ? 'hidden' : 'hiddenMobile'}
    animate={isDesktop ? 'visible' : 'visibleMobile'}
    exit={isDesktop ? 'exit' : 'exitMobile'}
  >
    <BioContainer>
      <TextContainer>
        <p>Hi, my name is Teva Henry. I'm currently based in Auckland, NZ.<br/> If I had to describe myself in one word? I'd say <strong>CURIOUS</strong>.<br/> Curious about the world and how technology can help us live in it.
        <br/><br/>I'm more inclined to learn the cutting edge, without losing sight of the fundamentals, and see machine learning and augmented reality as possible paths to take on my <strong>LEARNING</strong> journey.
        <br/><br/>I'm looking to join a high <strong>GROWTH</strong> environment with a team seeking to make an impact.</p>
      </TextContainer>
    </BioContainer>
    {!isDesktop && <SkillsContainer>
      <Corner />
      <h3>Technologies I'm currently focusing on:</h3>
      <ul>
        <li>React / Redux</li>
        <li>Jest / Enzyme</li>
        <li>Node / Express</li>
        <li>MongoDB / GraphQL</li>
      </ul>
    </SkillsContainer>}
  </AboutPageContainer>
)

export default AboutPage