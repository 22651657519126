import React, { useState, useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { ThemeProvider } from 'styled-components'

import Header from './components/Header/Header'
import Homepage from './pages/Homepage/Homepage'
import ProjectsPage from './pages/ProjectsPage/ProjectsPage'
import AboutPage from './pages/AboutPage/AboutPage'
import SidePanel from './components/SidePanel/SidePanel'
import Footer from './components/Footer/Footer'

import { AppContainer } from './AppStyles'
import {lightTheme, darkTheme} from './themes/themes'

function App() {
  const location = useLocation()
  const [project, setProject] = useState(null)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024)
  const [darkMode, setDarkMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)
  const [showDrawer, setShowDrawer] = useState(false)

  const updateMedia = () => setIsDesktop(window.innerWidth >= 1024)
  const handleProject = (project) => setProject(project)
  const handleDarkMode = () => setDarkMode(darkMode => !darkMode)
  const handleNavDrawer = (event) => {
    event.preventDefault()
    !isDesktop && setShowDrawer(show => !show)
  }

  useEffect(() => {
    isDesktop && setShowDrawer(false)
  }, [isDesktop])

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  })

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <AppContainer>
        <Header showDrawer={showDrawer} setShowDrawer={setShowDrawer} handleNavDrawer={handleNavDrawer} handleProject={handleProject} darkMode={darkMode} handleDarkMode={handleDarkMode} isDesktop={isDesktop}/>
        {isDesktop && <SidePanel project={project}/>}
        <AnimatePresence>
          <Switch location={location} key={location.key}>
            <Route path='/' exact render={() => <Homepage isDesktop={isDesktop}/>} />
            <Route path='/projects' render={() => <ProjectsPage handleProject={handleProject} isDesktop={isDesktop}/>}/>
            <Route path='/about' render={() => <AboutPage isDesktop={isDesktop}/>} />
          </Switch>
        </AnimatePresence>
        {isDesktop && <Footer />}
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
