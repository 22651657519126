import styled from 'styled-components'

export const AppContainer = styled.div`
    height: 100vh;
    width: 100vw;
    background: ${props => props.theme.colors.main};
    font-family: 'Montserrat', sans-serif;

    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-rows: 10vh 1fr;
        grid-template-areas:
        "header side"
        "content side";
    }

    a:hover, a:visited, a:link, a:active {
        text-decoration: none;
    }

`