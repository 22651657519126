import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const IntroductionContainer = styled.div`
  height: 100%;
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: ${props => props.theme.colors.secondary};
`

export const StyledLink = styled(Link)`
  padding: 15px 30px;
  border-radius: 6px;
  color: ${props => props.theme.colors.main};
  background: ${props => props.theme.colors.secondary};
  transform: translateY(-8vh);
  font-size: calc(1vw + 1em);
  text-transform: uppercase;
  box-shadow: 0px 10px 13px -7px #000000, 0px 6px 17px -3px rgba(84,84,84,0);

  :hover {
    transform: translateY(-8vh) scale(1.02);
    transition: transform 0.3s ease-in-out;
  }
`

export const TextContainer = styled.div`
  font-size: calc(2vw + 1em);
  transform: translateY(-8vh);
`