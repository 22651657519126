import React, { useState, Fragment } from 'react'

import {
  ProjectCardContainer,
  ProjectCardPreviewContainer,
  ProjectCardVideo,
  DesktopProjectCardVideo,
  TechIconImage,
  LinksContainer,
  TextContainer,
  ImageContainer,
  Overlay,
  DesktopOverlay
} from './ProjectCardStyles';

const ProjectCard = ({handleProject, project, isDesktop }) => {
  const [ isExpanded, setIsExpanded ] = useState(false);
  
  const expandHandler = () => {
    isDesktop && handleProject(project)
    isExpanded && handleProject(null)
    setIsExpanded(isExpanded => !isExpanded)
  }

  const { id, area, title, shortDescription, image, techStack, longDescription, video, gitHub, demo } = project
  
  return !isExpanded ?
          <ProjectCardPreviewContainer className={`project-${id}`} onClick={expandHandler} area={area}>
            <TextContainer id={id}>
              <h3>{title}</h3>
              <p>{shortDescription}</p>
            </TextContainer>
            <ImageContainer id={id} image={image} />
          </ProjectCardPreviewContainer>
        : isDesktop ?
            <DesktopProjectCardVideo>
              <video src={`/images/${video}`} autoPlay loop muted playsinline />
              <DesktopOverlay onClick={expandHandler}/>
            </DesktopProjectCardVideo>
          :
            <Fragment>
              <ProjectCardContainer >
                <h3>{title}</h3>
                <ul className="tech">
                  {techStack.map(tech => <li key={tech}><TechIconImage src={`/images/${tech}.svg`} alt={`${tech} icon`}/></li>)}
                </ul>
                <p>{longDescription}</p>
                <ProjectCardVideo src={`/images/${video}`} autoPlay loop muted playsinline/>
                <LinksContainer>
                  <a href={gitHub} target="_blank" rel="noopener noreferrer">GitHub</a>
                  <a href={demo} target="_blank" rel="noopener noreferrer">Demo</a>
                </LinksContainer>
              </ProjectCardContainer>
              <Overlay onClick={expandHandler} />
            </Fragment>
}

export default ProjectCard;