import styled from 'styled-components'

export const ProjectsContainer = styled.div`
  grid-area: projects;
  height: 90vh;
  width: 100%;
  display: grid;
  grid-template: repeat(3, 30vh) / repeat(3, 1fr);
  grid-template-areas: 
    "project1 project1 project1"
    "project2 project2 project2"
    "project3 project3 project3";

  .project-2 {
    flex-direction: row-reverse;
  }

  .project-1 {
    flex-direction: row;
  }

  @media screen and (min-width: 768px) {
    grid-template-areas: 
      "project1 project1 project1"
      "project2 project2 project2"
      "project3 project3 project3";

    .project-2 {
      flex-direction: row-reverse;
    }

    .project-1 {
      flex-direction: row;
    }
  }

  @media screen and (min-width: 1024px) {
    grid-template-areas: 
      "project1 project1 project2"
      "project1 project1 project2"
      "project3 project3 project2";
    
    .project-2 {
      flex-direction: column;
    }

    .project-1 {
      flex-direction: column-reverse;
    }

    .project-3 {
      flex-direction: row-reverse;
    }
  }

  @media screen and (min-width: 2560px) {
    height: 90vh;
    background: ${props => props.theme.colors.secondary};
  }
`