import styled from 'styled-components'
import { motion } from 'framer-motion'

import { ReactComponent as Triangle} from '../../assets/triangle.svg'

export const AboutPageContainer = styled(motion.div)`
  height: 90vh;
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem, max(2.2vh, 1.7vw), 3rem);

  @media screen and (min-width: 1024px) {
    grid-area: content;
    height: 100%;
  }

  @media screen and (min-width: 1400px) {
    font-size: clamp(0.8rem, max(2.2vh, 2vw), 3rem);
  }
`;

export const BioContainer = styled.div`
  height: 70vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1024px) {
    height: 90vh;
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.colors.text};
  
  p {
    line-height: 170%;
  }

  @media screen and (min-width: 1024px) {
    width: 80%;
  }
`;

export const SkillsContainer = styled(motion.div)`
  height: 20vh;
  width: 100vw;
  overflow: hidden;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.accent};
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    width: 80%;
    margin-bottom: 0;
  }

  ul {
      height: 10vh;
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  
  li {
    justify-self: flex-start;
    width: 45%;
  }

  @media screen and (min-width: 480px) {
    ul {
      width: 55%;
    }

    h3 {
      text-align: center;
    }
  }
`;

export const CornerContainer = styled(motion.div)`

`
export const Corner = styled(Triangle)`
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  opacity: 0.8;
  stroke: ${props => props.theme.colors.main};

  @media screen and (min-width: 1024px) {
    width: 25%;
  }

  @media screen and (min-width: 1700px) {
    width: 20%;
  }

  @media screen and (min-width: 2560px) and (min-aspect-ratio: 2/1) {
    width: 10%;
  }
`;

export const StyledSVG = styled.svg`
  fill: ${props => props.theme.colors.accent};
  height: 5vh; 
`