import React from 'react'

import { MotifContainer, TatooSVG } from './MotifStyles'

const Motif = () => (
    <MotifContainer
        exit={{opacity: 0}}
    >
        <TatooSVG /> 
    </MotifContainer>
   
)

export default Motif;