import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { ReactComponent as logo } from '../../assets/Personal-logo.svg'

export const HeaderContainer = styled.div`
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 155;
  background: ${props => props.theme.colors.secondary};
`

export const NavContainer = styled.nav`
  height: 10vh;
  width: 100%;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1024px) {
    justify-content: space-evenly;
  }
`

export const NavList = styled.ul`
  width: 80%;
  list-style: none;
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const StyledLogo = styled(Link)`
  height: 10vh;
  width: 20%;
`

export const StyledLink = styled(Link)`
  width: 20vw;
  color: ${props => props.theme.colors.main};
  font-size: 3.5vmin;
  font-weight: 650;
`

export const NavDrawerButton = styled.button`
  z-index: 10;
  color: ${props => props.theme.colors.main};
  font-size: 3rem;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;

  @media (min-width: 1024px) {
    display: none;
  }
`

export const Logo = styled(logo)`
  height: 10vh;

  .main {
    fill: ${props => props.theme.colors.main};
    stroke: ${props => props.theme.colors.main};
  }

  .secondary {
    fill: ${props => props.theme.colors.secondary};
    stroke: ${props => props.theme.colors.secondary};
  }
`