import React from 'react'

import ThemeToggle from '../ThemeToggle/ThemeToggle'

import {
  MobileNavContainer,
  MobileNavLink,
  NavDrawerFooter,
  SocialLinks
} from './MobileNavStyles'

const MobileNav = ({ showDrawer, closeNavDrawer, darkMode, handleDarkMode }) => (
  <MobileNavContainer showDrawer={showDrawer}>
    <MobileNavLink to='/projects' onClick={closeNavDrawer}>Projects</MobileNavLink>
    <MobileNavLink to='/about' onClick={closeNavDrawer}>About me</MobileNavLink>
    <ThemeToggle darkMode={darkMode} handleDarkMode={handleDarkMode}/>
    <NavDrawerFooter>
      <SocialLinks>
        <a href='https://github.com/TevaHenry' target='_blank' rel='noopener noreferrer'>
          <svg width={50} height={50} viewBox='0 0 800 800'>
            <title>{'GitHub'}</title>
            <path d='M400 139c144 0 260 116 260 260 0 115-75 213-178 247-9 3-17-2-17-13v-71c0-35-18-48-18-48 57-6 119-28 119-128 0-44-27-70-27-70s14-29-2-69c0 0-22-7-72 27-42-12-88-12-130 0-50-34-72-27-72-27-16 40-2 69-2 69s-27 26-27 70c0 100 62 122 119 128 0 0-14 10-17 35-15 7-53 18-76-22 0 0-13-25-39-27 0 0-26 0-2 16 0 0 17 8 29 38 0 0 16 51 88 35v44c0 11-9 16-18 13-103-34-178-132-178-247 0-144 116-260 260-260z' />
          </svg>
        </a>
        <a href='https://www.linkedin.com/in/tevahenry' target='_blank' rel='noopener noreferrer'>
          <svg width={50} height={50} viewBox='0 0 800 800'>
            <title>{'LinkedIn'}</title>
            <path d='M268 629h-97V319h97zm157 0h-97V319h93v42h1q31-50 93-50 114 0 114 133v185h-96V466q0-70-49-70-59 0-59 69z' />
            <circle cx={219} cy={220} r={56} />
          </svg>
        </a>
        <a href='https://twitter.com/TevaHenry' target='_blank' rel='noopener noreferrer'>
          <svg width={50} height={50} viewBox='0 0 800 800'>
            <title>{'Twitter'}</title>
            <path d='M679 239s-21 34-55 57c7 156-107 329-314 329-103 0-169-50-169-50s81 17 163-45c-83-5-103-77-103-77s23 6 50-2c-93-23-89-110-89-110s23 14 50 14c-84-65-34-148-34-148s76 107 228 116c-22-121 117-177 188-101 37-6 71-27 71-27s-12 41-49 61c30-2 63-17 63-17z' />
          </svg>
        </a>
        <a href='https://resume.tevahenry.com' target='_blank' rel='noopener noreferrer'>
        <svg width={50} height={50} viewBox='0 0 124 124'>
            <title>{'Resume'}</title>
            <path d="M107.85862,1.9495h-65.678A4.582,4.582,0,0,0,38.91975,3.301L13.20025,29.02062a4.57286,4.57286,0,0,0-1.35156,3.26074v85.474a8.30572,8.30572,0,0,0,8.29766,8.29511h87.71227a8.30306,8.30306,0,0,0,8.29268-8.29511V10.24716A8.30532,8.30532,0,0,0,107.85862,1.9495Zm-.932,86.46872v28.41023H21.07336V36.89373H38.49775a8.3037,8.3037,0,0,0,8.29517-8.29523V11.17416h60.13372Z"/>
            <path d="M64.82067,103.63479H37.31734a2.15847,2.15847,0,1,0,0,4.31694H64.82067a2.15847,2.15847,0,1,0,0-4.31694Z"/>
            <path d="M35.15887,94.235a2.163,2.163,0,0,0,2.15847,2.15853H90.68272a2.15853,2.15853,0,0,0,0-4.31706H37.31734A2.15649,2.15649,0,0,0,35.15887,94.235Z"/>
            <path d="M45.33184,53.8263l1.02881,5.30307a6.73963,6.73963,0,0,0,13.23125,0l1.02881-5.30307a7.09933,7.09933,0,0,0-7.03341-8.056H52.36525A7.09933,7.09933,0,0,0,45.33184,53.8263Z"/>
            <path d="M52.97631,73.53253l-6.89219-6.89219-7.18288,2.55109a2.002,2.002,0,0,0-1.28842,1.47043l-2.454,11.549H70.79369l-2.454-11.549a2.00193,2.00193,0,0,0-1.28842-1.47043l-7.18288-2.55109Z"/>
          </svg>
        </a>
      </SocialLinks>
      <small>&copy; Copyright 2020, Teva Henry</small>
    </NavDrawerFooter>
  </MobileNavContainer>
)

export default MobileNav