import React, { Fragment } from 'react'
// import { AnimatePresence } from 'framer-motion'


import { ProjectDetailContainer, DetailsContainer, TechStack, ProjectLinksContainer, MotionPanelL, MotionPanelR, DemoIcon, GitHubIcon, StyledPanelL, StyledPanelR } from './ProjectDetailStyles'

const PanelVariants = {
  hiddenL: {
    x: '-20vw'
  },
  visibleL: {
    x: 0,
    transition: {duration: 1}
  },
  exitL: {
    x: '-20vw',
    transition: {duration: 1}
  },
  hiddenR: {
    x: '20vw'
  },
  visibleR: {
    x: 0,
    transition: {duration: 1}
  },
  exitR: {
    x: '20vw',
    transition: {duration: 1}
  }
}

const ProjectDetail = ({ project }) => {
  return (
    <ProjectDetailContainer
      exit={{}}
    >
      {/* <AnimatePresence> */}
      {!project && 
      <Fragment>
        <MotionPanelL
          key='left'
          variants={PanelVariants}
          initial='hiddenL'
          animate='visibleL'
          exit='exitL'
        >
          <StyledPanelL height={1700}/>
        </MotionPanelL>
        <MotionPanelR
          key='right'
          variants={PanelVariants}
          initial='hiddenR'
          animate='visibleR'
          exit='exitR'
        >
          <StyledPanelR height={1700}/>
        </MotionPanelR>
        </Fragment>
      }
      {/* </AnimatePresence> */}
        {project && <DetailsContainer
        initial={{opacity: 0, scale: 0.1}}
        animate={{opacity: 1, scale: 1}}
        transition={{ duration: 1, ease: 'easeInOut'}}
        exit={{opacity: 0, scale: 0.1}}
      >
        <h2>{project.title}</h2>
        <TechStack>
          {project.techStack.map(tech => <li key={tech}><img src={`/images/${tech}.svg`} alt={`${tech} icon`} /></li>)}
        </TechStack>
        <p>{project.longDescription}</p>
        <ProjectLinksContainer>
          <a href={project.gitHub} target="_blank" rel="noopener noreferrer">
            <GitHubIcon viewBox='0 0 800 800'>
              <title>{'GitHub'}</title>
              <path d='M400 139c144 0 260 116 260 260 0 115-75 213-178 247-9 3-17-2-17-13v-71c0-35-18-48-18-48 57-6 119-28 119-128 0-44-27-70-27-70s14-29-2-69c0 0-22-7-72 27-42-12-88-12-130 0-50-34-72-27-72-27-16 40-2 69-2 69s-27 26-27 70c0 100 62 122 119 128 0 0-14 10-17 35-15 7-53 18-76-22 0 0-13-25-39-27 0 0-26 0-2 16 0 0 17 8 29 38 0 0 16 51 88 35v44c0 11-9 16-18 13-103-34-178-132-178-247 0-144 116-260 260-260z' />
            </GitHubIcon>
          </a>
          <a href={project.demo} target="_blank" rel="noopener noreferrer">
            <DemoIcon />
          </a>
        </ProjectLinksContainer>
      </DetailsContainer>
      } 
    </ProjectDetailContainer>
  )
}

export default ProjectDetail