import React from 'react'

import MobileNav from '../../components/MobileNav/MobileNav'
import ThemeToggle from '../ThemeToggle/ThemeToggle'

import { 
  HeaderContainer,
  NavContainer,
  NavList,
  StyledLogo,
  StyledLink,
  NavDrawerButton,
  Logo
} from './HeaderStyles'


const Header = ({ showDrawer, setShowDrawer, handleNavDrawer, handleProject, darkMode, handleDarkMode, isDesktop }) => (
  <HeaderContainer>
    <NavContainer>
      <StyledLogo to='/'>
        <Logo />
      </StyledLogo>
      <NavList>
        <StyledLink to='/projects' onClick={() => handleProject(null)}>
          <li>Projects</li>
        </StyledLink>
        <StyledLink to='/about'>
          <li>About Me</li>
        </StyledLink>
        <ThemeToggle darkMode={darkMode} handleDarkMode={handleDarkMode}/>
      </NavList>
      <NavDrawerButton onClick={handleNavDrawer}>{ showDrawer ? '\u003e' : '\u2261' }</NavDrawerButton>
    </NavContainer>
    <MobileNav showDrawer={showDrawer} closeNavDrawer={() => setShowDrawer(showDrawer => !showDrawer)} darkMode={darkMode} handleDarkMode={handleDarkMode}/>
  </HeaderContainer>
)

export default Header