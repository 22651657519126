import styled, { keyframes } from 'styled-components'
import { motion } from 'framer-motion'
import {ReactComponent as Tatoo} from '../../assets/final.svg'

const draw = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const fill = props => keyframes`
  to {
    fill: ${props.theme.colors.main};
  }
`

export const MotifContainer = styled(motion.div)`
  height: 100%;
  grid-area: side;
`

export const TatooSVG = styled(Tatoo)`
  height: 100%;
  width: 100%;
  display: none;

  @media (min-width: 1024px) {
    display: initial;
  }

  /* @media (min-width: 2560px) and (min-aspect-ratio: 2/1) {
    width: 14%;
    height: 80%;
  } */
  
  #layer1 path {
    fill: transparent;
    stroke: ${props => props.theme.colors.main};
    stroke-width: 23;
    stroke-dasharray: 7935;
    stroke-dashoffset: 7935;
    animation: ${draw} 0.7s linear forwards;
  }

  #layer2 path {
    fill: transparent;
    stroke: ${props => props.theme.colors.main};
    stroke-width: 23;
    stroke-dasharray: 4960;
    stroke-dashoffset: 4960;
    animation: ${draw} 0.6s linear forwards 0.5s;
  }

  #layer3 path {
    fill: transparent;
    stroke: ${props => props.theme.colors.main};
    stroke-width: 23;
    stroke-dasharray: 1477;
    stroke-dashoffset: 1477;
    animation: ${draw} 0.5s linear forwards 0.8s;
  }

  #layer4 path {
    fill: transparent;
    stroke: ${props => props.theme.colors.main};
    stroke-width: 23;
    stroke-dasharray: 2356;
    stroke-dashoffset: 2356;
    animation: ${draw} 0.6s linear forwards 0.9s;
  }

  #layer5 path {
    fill: transparent;
    stroke: ${props => props.theme.colors.main};
    stroke-width: 10;
    stroke-dasharray: 3119;
    stroke-dashoffset: 3119;
    animation: ${draw} 0.6s linear forwards 1.3s;
  }

  #layer6 path {
    fill: transparent;
    stroke: ${props => props.theme.colors.main};
    stroke-width: 3;
    stroke-dasharray: 658;
    stroke-dashoffset: 658;
    animation: ${draw} 0.4s linear forwards 0.3s, ${fill} 0.8s ease-in forwards 3s;
  }

  #layer7 path {
    fill: transparent;
    stroke: ${props => props.theme.colors.main};
    stroke-width: 10;
    stroke-dasharray: 8177;
    stroke-dashoffset: 8177;
    animation: ${draw} 0.6s linear forwards 1.65s;
  }

  #layer8 path {
    fill: transparent;
    stroke: ${props => props.theme.colors.main};
    stroke-width: 3;
    stroke-dasharray: 135;
    stroke-dashoffset: 135;
    animation: ${draw} 0.6s linear forwards 2s;
  }

  #layer9 path {
    fill: transparent;
    stroke: ${props => props.theme.colors.main};
    stroke-width: 3;
    stroke-dasharray: 1799;
    stroke-dashoffset: 1799;
    animation: ${draw} 0.7s linear forwards 2s, ${fill} 0.8s ease-in forwards 3s;
  }

  #layer10 {
    path {
      stroke-width: 2;
    }

    .fill1 {
      fill: transparent;
      stroke: ${props => props.theme.colors.main};
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${draw} 0.7s linear forwards 2.1s;
    }

    .fill2 {
      fill: transparent;
      stroke: ${props => props.theme.colors.main};
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${draw} 0.7s linear forwards 2.25s;
    }

    .fill3 {
      fill: transparent;
      stroke: ${props => props.theme.colors.main};
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${draw} 0.7s linear forwards 2.4s;
    }

    .fill4 {
      fill: transparent;
      stroke: ${props => props.theme.colors.main};
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${draw} 0.7s linear forwards 2.55s;
    }

    .fill5 {
      fill: transparent;
      stroke: ${props => props.theme.colors.main};
      stroke-dasharray: 600;
      stroke-dashoffset: 600;
      animation: ${draw} 0.7s linear forwards 2.7s;
    }
  }
`