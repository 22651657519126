import styled from 'styled-components';

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: ${props => props.theme.colors.main};
  opacity: 0.7;
  font-size: 14px;
  transform: rotate(-90deg) translateX(50%) translateY(400%);

  @media screen and (min-width: 2560px) {
    font-size: 2.5vmin;
  }
`;