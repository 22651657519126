import styled from 'styled-components'
import {ReactComponent as Dark} from '../../assets/moon.svg'
import {ReactComponent as Light} from '../../assets/mode.svg'

export const ThemeToggleContainer = styled.div`
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  background: ${props => props.theme.colors.main};
  display: flex;
  justify-content: center;
  align-items: center;
  fill: ${props => props.theme.colors.secondary};

  :hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: transform 0.3 ease-in-out;
  }
`

export const LightModeIcon = styled(Light)`
  height: 4vh;
`

export const DarkModeIcon = styled(Dark)`
  height: 4vh;
`