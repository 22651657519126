export const lightTheme = {
  colors: {
    main: '#F2F2F2',
    secondary: '#033159',
    accent: '#F2F2F2',
    text: '#1C2733',
    cards: '#fff'
  }
}

export const darkTheme =  {
  colors: {
    main: '#6b778d',
    secondary: '#17223b',
    accent: '#F2F2F2',
    text: '#F2F2F2',
    cards: '#5c677d'
  }
}