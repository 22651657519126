import styled from 'styled-components'

export const ProjectCardPreviewContainer = styled.div`
  height: 27vh;
  background: ${props => props.theme.colors.cards};
  box-shadow: 0px 10px 13px -7px #000000, 0px 6px 17px -3px rgba(84,84,84,0);
  color: ${props => props.theme.colors.text};
  margin: 1vh;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: ${props => props.area};
  border-radius: 10px;
  overflow: hidden;

  h3 {
    font-family: 'Gentium Basic', serif;
    text-transform: uppercase
  }

  @media screen and (min-width: 1024px) {
    height: initial;
    font-size: calc(1em + 0.7vw);
    
    &:hover {
      cursor: pointer;
      transform: scale(1.01);
    }
  }

  @media screen and (min-width: 1500px) {
    font-size: calc(1em + 1.2vw);
  }
`

export const ProjectCardContainer = styled.div`
  height: 90%;
  z-index: 50;
  background: ${props => props.theme.colors.main};
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  border-radius: 6px;
  box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.25);
  color: ${props => props.theme.colors.text};
  line-height: 2rem;
  display: flex;
  flex-direction: column;
  margin: auto 10px;
  padding: 0 20px;
  justify-items: center;
  align-items: center;
  overflow: scroll;
  font-size: calc(1em + 1.3vw);
  
  h3 {
    font-size: calc(1em + 1.4vw);
  }

  ul {
    margin: 0;
    padding: 0;
  }

  .tech {
    width: 95%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
  }

  @media screen and (min-width: 1024px) {
    height: 70%;
    display: grid;
    grid:  12vh 2fr 1fr / repeat(2, 1fr);
    padding: 5vh;
  }

  @media screen and (min-width: 2560px) {
    height: 80%;
    margin: 20px;
  }
`

export const ProjectCardVideo = styled.video`
  height: 20vh;

  @media screen and (min-width: 1024px) {
    height: 30vh;
    margin-left: 40px;
  }
`

export const TechIconImage = styled.img`
  height: 3vh;
  width: auto;

  @media screen and (min-width: 768px) {
    height: 5vh;
  }
`

export const LinksContainer = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;

  a {
    height: 2em;
    width: 4em;
    border-radius: 6px;
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.main};
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
  }

  @media screen and (min-width: 1024px) {
   
  }
`

export const TextContainer = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin-bottom: 0;
  }

  p {
    padding: 0 20px;
  }

  @media screen and (min-width: 1024px) {
    height: ${props => props.id === 3 ? '100%' : '30%'};
    width: ${props => props.id === 3 ? '40%' : '100%'};
  }
`

export const ImageContainer = styled.div`
  height: 100%;
  width: 60%;
  /* height: ${props => props.id === 2 ? '100%' : '100%'}; */
  /* width: ${props => props.id === 2 ? '100%' : '50%'}; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  overflow: hidden;
  background-image: url(${props => `/images/${props.image[0]}`});
  background-size: cover;
  background-position: center;

  /* img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
  } */

  @media screen and (min-width: 1024px) {
    height: ${props => props.id === 3 ? '100%' : '70%'};
    width: ${props => props.id === 3 ? '60%' : '100%'};
    background-image: url(${props => `/images/${props.image[1]}`});
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 15;
`

export const DesktopProjectCardVideo = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  border-radius: 6px;
  box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    height: auto;
    width: 80%;
    border: white 4px solid;
    border-radius: 8px;
    z-index: 50;
  }
`

export const DesktopOverlay = styled.div`
  position: absolute;
  top: 10%;
  left: 0;
  bottom: 0;
  right: 30%;
  background: rgba(0,0,0,0.5);
  z-index: 15;
`