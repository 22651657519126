import React from 'react'
import { ThemeToggleContainer, DarkModeIcon, LightModeIcon } from './ThemeToggleStyles'

export default function ThemeToggle({darkMode, handleDarkMode}) {
  return (
    <ThemeToggleContainer onClick={handleDarkMode}>
      {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
    </ThemeToggleContainer>
  )
}
