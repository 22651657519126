import React from 'react';

import Greeting from '../Greeting/Greeting';

import {
  IntroductionContainer,
  StyledLink,
  TextContainer
} from './IntroductionStyles';

const Introduction = () => (
  <IntroductionContainer>
      <Greeting />
      <TextContainer>
        <h1>I'm Teva</h1>
        <h4>Fullstack Developer</h4>
      </TextContainer>
      <StyledLink to='/projects'>Portfolio</StyledLink>
  </IntroductionContainer>
)

export default Introduction;