import React, { useState, useEffect } from 'react';

import { GreetingContainer } from './GreetingStyles';

const Greeting = () => {
  const [ greeting, setGreeting ] = useState('Hi,');

  useEffect(() => {
    const greetings = ['Hi,', 'Kia ora,', 'Ia ora na,'];
    let count = 0;

    const interval = setInterval(() => {
      setGreeting(greetings[count % greetings.length])
      count++
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <GreetingContainer>
      <h3>{greeting}</h3>
    </GreetingContainer>
  )
}

export default Greeting;