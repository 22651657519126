import styled from 'styled-components'
import { motion } from 'framer-motion'

export const HomepageContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (min-width: 1024px) {
    grid-area: content; 
  }
`

export const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1024px) {
    width: 70vw;
  }
`