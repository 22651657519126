import React from 'react'

import Projects from '../../components/Projects/Projects'

import {
  ProjectsPageContainer,
} from './ProjectsPageStyles'

const projectsVariants = {
  hidden: {
   y: '100vh'
  },
  hiddenMobile: {
    x: '-100vw'
  },
  visible: {
   y: 0,
   transition: {delay: 0.5, duration: 0.7}
  },
  visibleMobile: {
    x: 0,
    transition: {delay: 0.5, duration: 0.7}
  },
  exit: {
    y: '-100vh',
    transition: { ease: 'easeInOut', duration: 0.7}
  },
  exitMobile: {
    x: '100vw',
    transition: { ease: 'easeInOut', duration: 0.7}
  }
}

const ProjectsPage = ({ handleProject, isDesktop }) => (
  <ProjectsPageContainer
    variants={projectsVariants}
    initial={isDesktop ? 'hidden' : 'hiddenMobile'}
    animate={isDesktop ? 'visible' : 'visibleMobile'}
    exit={isDesktop ? 'exit' : 'exitMobile'}
  >
    <Projects handleProject={handleProject} isDesktop={isDesktop}/>
  </ProjectsPageContainer>
)

export default ProjectsPage