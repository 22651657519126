import styled from 'styled-components'
import { motion } from 'framer-motion'

import {ReactComponent as Demo} from '../../assets/link.svg'
import {ReactComponent as PanelL} from '../../assets/panel.svg'
import {ReactComponent as PanelR} from '../../assets/Kowhaiwhai.svg'

export const MotionPanelL = styled(motion.div)`
  height: 100%;

  .main {
    stroke: ${props => props.theme.colors.main};
    fill: ${props => props.theme.colors.main};
  }
`

export const MotionPanelR = styled(motion.div)`
  height: 100%;

  .main {
    stroke: ${props => props.theme.colors.main};
    fill: ${props => props.theme.colors.main}; 
  }
`

export const ProjectDetailContainer = styled(motion.div)`
  grid-area: side;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  color: ${props => props.theme.colors.text};
  
  h2 {
    font-family: 'Gentium Basic', serif;
    text-transform: uppercase
  }
`

export const DetailsContainer = styled(motion.div)`
  height: 90%;
  width: 75%;
  border-radius: 12px;
  background: ${props => props.theme.colors.main};
  padding: 15px;
  font-size: 2.5vh;
  line-height: 200%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 10px 13px -7px #000000, 0px 6px 17px -3px rgba(84,84,84,0);
`

export const TechStack = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  list-style: none;

  img {
    height: 3vh;
    width: auto;
    
    @media screen and (min-width: 768px) {
      height: 5vh;
    }
  }
`

export const ProjectLinksContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  fill: ${props => props.theme.colors.secondary};

  a {
    :hover {
      transform: scale(1.1);
      transition: transform 0.2s ease-in-out;
    }
  }
`

export const DemoIcon = styled(Demo)`
  height: 5vh;
  width: 8vh;
  fill: ${props => props.theme.colors.secondary};
`

export const GitHubIcon = styled.svg`
  height: 7vh;
  fill: ${props => props.theme.colors.secondary};
`

export const StyledPanelL = styled(PanelL)`
  height: 200vh;
`

export const StyledPanelR = styled(PanelR)`
  height: 200vh;
`