import React from 'react'

import Introduction from '../../components/Introduction/Introduction'

import { HomepageContainer } from './HomepageStyles'

const homepageVariants = {
  hidden: {
   opacity: 0
  },
  visible: {
   opacity: 1,
   transition: {delay: 0.7, duration: 1.5}
  },
  exit: {
    y: '-100vh',
    transition: { ease: 'easeInOut', duration: 1}
  },
  exitMobile: {
    x: '100vw',
    transition: { ease: 'easeInOut', duration: 0.7}
  }
}

const Homepage = ({ isDesktop }) => (
  <HomepageContainer
    variants={homepageVariants}
    initial='hidden'
    animate='visible'
    exit={isDesktop ? 'exit' : 'exitMobile'}
  >
    <Introduction />
  </HomepageContainer>
)

export default Homepage