import React from 'react'

import ProjectCard from '../ProjectCard/ProjectCard'

import projectData from './projectData'

import { ProjectsContainer } from './ProjectsStyles'

const Projects = ({ handleProject, isDesktop }) => (
  <ProjectsContainer>
    <ProjectCard project={projectData[0]} handleProject={handleProject} isDesktop={isDesktop}/> 
    <ProjectCard project={projectData[1]} handleProject={handleProject} isDesktop={isDesktop}/>
    <ProjectCard project={projectData[2]} handleProject={handleProject} isDesktop={isDesktop}/>
  </ProjectsContainer>
)

export default Projects