import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const MobileNavContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 90vh;
  width: 100vw;
  z-index: 15;
  color: ${props => props.theme.colors.main};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: ${props => props.theme.colors.secondary};
  transform: translateX(${props => props.showDrawer ? '0': '100vw'});
  transition: transform 0.3s ease-out;
`

export const MobileNavLink = styled(Link)`
  color: ${props => props.theme.colors.main};;
  font-size: 5vmin;
  font-weight: 650;
`

export const SocialLinks = styled.div`
  width: 120%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  a {
    background: #fff;
    border-radius: 6px;
    background: ${props => props.theme.colors.main};
  }

  svg {
    fill: ${props => props.theme.colors.secondary};
  }
`

export const NavDrawerFooter = styled.div`
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`