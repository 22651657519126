import styled from 'styled-components'
import { motion } from 'framer-motion'

export const ProjectsPageContainer = styled(motion.div)`
  background: ${props => props.theme.colors.secondary};
  height: 90vh;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    grid-area: content;
    background: transparent;
  }
`

export const LBord = styled.div`
  grid-area: lbord;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;

  @media screen and (max-width: 640px) {
    display: none;
  }
`

export const RBord = styled.div`
  grid-area: rbord;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;

  @media screen and (max-width: 640px) {
    display: none;
  }
`
